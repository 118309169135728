import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DefaultButton,
  IconButton,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import {
  cancelIcon,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackStylesHeight100,
} from 'globalStyles';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import Joi from 'joi';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import AuditTrail from 'components/Audit/AuditTrail';
import { ToastContainer } from 'react-toastify';
import { globalToastDelay } from 'globalConstants';
import ActivityTrail from 'components/Activity/ActivityTrail';
import OverlayLoader from 'components/Loading/OverlayLoader';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import { ISingleTaskTypeProps } from 'components/Tasks/SingleTask';
import { ApprovalTaskDetail } from './ApprovalTaskDetail';
import TaskTypeForm from 'components/Tasks/Forms/TaskTypeForm';
import { ApprovalState } from 'models/approval';
import { TaskDetailOwner } from '../Details/TaskDetailOwner';
import { getTaskViewModalStackStyles, getTaskViewModalStyles } from 'components/Tasks/View/TaskRenderHelpers';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';

interface IApprovalTaskProps extends ISingleTaskTypeProps {
  onSaveAsNewTask: () => void;
  onChangeCompletionDate: (completionDate: Date) => void;
}

const ApprovalTask = (props: IApprovalTaskProps) => {
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent']);
  const appContext = useContext(AppContext);
  const [err, setErr] = useState<Joi.ValidationError | undefined>(undefined);
  const [selectedPivot, setSelectedPivot] = useState<string>(props.selectedPivot ?? 'Checklist');
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  useEffect(() => {
    props.onBeforeSaveCallback(onBeforeSave);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _localizedFields = {
      assignment: t('task:Assignment.Label'),
      startDateTime: t('task:Start.Label'),
      endDateTime: t('task:End.ErrorMessage'),
      name: t('task:Name.Label'),
      deadline: t('task:Deadline.ErrorMessage'),
    };

    const result = props.task.validate(_localizedFields);
    setErr(result.error);
  }, [t, props.task]);

  useEffect(() => {
    if (appContext.isMobileView) {
      setSelectedPivot('TaskDetails');
    } else if (props.task.taskTypeId) {
      setSelectedPivot('TaskType');
    } else {
      setSelectedPivot('Checklist');
    }
  }, [appContext.isMobileView, props.task.taskTypeId]);

  useEffect(() => {
    //Single task requests to show a specific pivot
    if (props.selectedPivot) {
      setSelectedPivot(props.selectedPivot);
    }
  }, [props.selectedPivot]);

  const onBeforeSave = (task: Task): Task | undefined => {
    //when the task is approved or rejected, set the task state to complete before saving
    if (isApprovedOrRejected(task)) {
      task.setComplete();
    }

    return task;
  };

  const validate = (fieldName: string): string | undefined => {
    if (err) {
      const fieldError = err.details.find((e) => {
        return e.context?.key === fieldName || e.message.toLowerCase().includes(fieldName.toLowerCase());
      });

      return fieldError ? fieldError.message : undefined;
    }

    return undefined;
  };

  const hasErrors = (): boolean => {
    return err ? true : false;
  };

  const cannotSaveTask = (): boolean => {
    return (props.task.taskId !== -1 && props.task.isEqual(props.orgTask)) || hasErrors();
  };

  const isApprovedOrRejected = (task: Task): boolean => {
    return task.approved !== ApprovalState.Pending;
  };

  const getIsOutlookCreateDisabled = (): boolean => {
    return (
      hasErrors() ||
      props.isActionPending ||
      !props.task.isAssigned() ||
      (props.disallowSchedule ?? false) ||
      isApprovedOrRejected(props.task) ||
      !canUpdateTaskField(props.task, props.canUpdate, TaskFieldTypes.OutlookCreate, appContext)
    );
  };

  const onCancel = () => {
    if (cannotSaveTask()) {
      props.onCancel();
    } else {
      setShowCancelDialog(true);
    }
  };

  const getTaskDetails = () => {
    return (
      <ApprovalTaskDetail
        task={props.task}
        orgTask={props.orgTask}
        onUpdate={(newTask: Task) => {
          props.onUpdateTask(newTask);
        }}
        getErrorMessage={validate}
        isActionPending={props.isActionPending}
        tags={props.tags}
        addTagToTaskState={props.addTagToTaskState}
        removeTagFromTaskState={props.removeTagFromTaskState}
        onChangeCompletionDate={props.onChangeCompletionDate}
        windowLevel={props.windowLevel}
        navigateExternal={props.navigateExternal}
        canUpdate={props.canUpdate}
      />
    );
  };

  //
  // Render helpers
  //
  const getOutlookButton = (): JSX.Element | null => {
    if (!props.task.eventId && !props.task.isSeries()) {
      //create
      return (
        <Stack>
          <Stack.Item>
            <PrimaryButton
              className="redlab-usetiful-task-scheduleinoutlook"
              disabled={getIsOutlookCreateDisabled()}
              onClick={() => {
                props.createInOutlook();
              }}
            >
              {t('task:Outlook.CreateOutlook')}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      );
    } else if (props.task.eventId && !props.task.isSeries()) {
      //remove
      return (
        <Stack>
          <Stack.Item>
            <PrimaryButton
              text={t('task:Outlook.RemoveOutlook')}
              disabled={
                hasErrors() ||
                props.isActionPending ||
                !canUpdateTaskField(props.task, props.canUpdate, TaskFieldTypes.OutlookRemove, appContext)
              }
              onClick={() => {
                props.removeFromOutlook();
              }}
            />
          </Stack.Item>
        </Stack>
      );
    }

    return null;
  };

  const footer = (
    <Stack horizontal={!appContext.isMobileView} verticalAlign="end" tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            disabled={props.isActionPending || cannotSaveTask()} //disable save as template
            onClick={async () => {
              props.onSave(props.task, false);
            }}
          >
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              onCancel();
            }}
          >
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack>
      </Stack.Item>
      <Stack.Item grow>
        <Stack horizontal horizontalAlign={appContext.isMobileView ? undefined : 'space-around'}>
          {getOutlookButton()}
        </Stack>
      </Stack.Item>
    </Stack>
  );

  //
  // Main render
  //
  return (
    <Modal forceFocusInsideTrap={false} isOpen={props.isOpen} styles={getTaskViewModalStyles(props.windowLevel)}>
      <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="templateTask" />
      {props.isLoading && <OverlayLoader text={t('task:Progress.Save')} />}
      <Stack
        verticalFill
        tokens={globalStackTokensGapSmall}
        styles={getTaskViewModalStackStyles(appContext.isMobileView, false)}
      >
        <Stack.Item>
          <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Stack.Item>
              <Stack horizontal={!appContext.isMobileView} verticalAlign="center" tokens={globalStackTokensGapMedium}>
                <Stack.Item>
                  <Text variant="xxLarge">{t('task:Title.Approval.Edit')}</Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <TaskDetailOwner
              orgTask={props.orgTask}
              task={props.task}
              onUpdate={props.onUpdateTask}
              canUpdate={props.canUpdate}
            />
          </Stack.Item>
          <Separator />
        </Stack.Item>
        <Stack.Item grow>
          <Stack horizontal styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
            {!appContext.isMobileView && (
              <Fragment>
                <Stack.Item styles={{ root: { width: '50%' } }}>{getTaskDetails()}</Stack.Item>
                <Separator vertical></Separator>
              </Fragment>
            )}
            <Stack.Item styles={!appContext.isMobileView ? { root: { width: '50%' } } : { root: { width: '100%' } }}>
              <Stack verticalFill tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <Pivot
                    selectedKey={selectedPivot}
                    onLinkClick={(item) => setSelectedPivot(item?.props.itemKey ?? '')}
                    overflowBehavior="menu"
                  >
                    {appContext.isMobileView && (
                      <PivotItem key="TaskDetails" itemKey="TaskDetails" headerText={t('task:Pivot.TaskDetails')} />
                    )}
                    {props.hasFormFeature && props.task.taskTypeId && props.task.taskTypeId > 0 && (
                      <PivotItem
                        key="TaskType"
                        itemKey="TaskType"
                        headerText={appContext.globalDataCache.taskTypes.get(props.task.taskTypeId).tabName}
                      />
                    )}
                    {props.task.commentTrailId > 0 && (
                      <PivotItem key="Activities" itemKey="Activities" headerText={t('task:Pivot.Activities')} />
                    )}
                    {props.task.auditTrailId > 0 && (
                      <PivotItem key="Audit" itemKey="Audit" headerText={t('task:Pivot.Audit')} />
                    )}
                  </Pivot>
                </Stack.Item>
                {selectedPivot === 'TaskDetails' && (
                  <Fragment>
                    <Stack.Item grow style={{ height: appContext.isMobileView ? '40vh' : 0 }}>
                      {getTaskDetails()}
                    </Stack.Item>
                  </Fragment>
                )}
                <TaskTypeForm
                  isOpen={selectedPivot === 'TaskType'}
                  task={props.task}
                  links={props.links}
                  compact={true}
                  readonly={props.task.isCompleted()}
                  onUpdateData={props.onUpdateKPIData}
                  onSetData={props.onSetKPIData}
                  onUpdateTaskForForm={props.onUpdateTaskForForm}
                  addLinks={props.addLinkToTaskState}
                  setLinks={props.setLinkToTaskState}
                  removeLink={props.removeLinkFromTaskState}
                  showErrors={props.showFormErrors}
                  onValidate={props.onValidateForm}
                ></TaskTypeForm>
                {selectedPivot === 'Activities' && (
                  <Stack.Item grow>
                    <ActivityTrail
                      auditTrailId={props.task.auditTrailId}
                      commentTrailId={props.task.commentTrailId}
                      taskId={props.task.taskId}
                      authSchemaIds={[props.task.authSchemaId]}
                    />
                  </Stack.Item>
                )}
                {selectedPivot === 'Audit' && (
                  <Stack.Item grow>
                    <AuditTrail auditTrailId={props.task.auditTrailId} />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Separator styles={{ root: { height: 1 } }} />
        </Stack.Item>
        <Stack.Item>{footer}</Stack.Item>
      </Stack>
      <DialogConfirmDelete
        onNo={() => {
          setShowRemoveDialog(false);
        }}
        onYes={() => {
          setShowRemoveDialog(false);
          props.onRemove();
        }}
        title={props.task.isSeries() ? t('task:Template.RemoveDialogTitle') : t('task:Remove.DialogTitle')}
        subText={props.task.isSeries() ? t('task:Template.RemoveDialogSubText') : t('task:Remove.DialogSubTitle')}
        hidden={!showRemoveDialog}
        confirmText={!props.task.isSeries() ? t('task:Remove.RemoveDialogConfirmText') : undefined}
      />
      <DialogYesNo
        onNo={() => {
          setShowCancelDialog(false);
        }}
        onYes={() => {
          setShowCancelDialog(false);

          props.onCancel();
        }}
        title={t('task:Cancel.DialogTitle')}
        subText={t('task:Cancel.DialogSubTitle')}
        hidden={!showCancelDialog}
      />
    </Modal>
  );
};

export default ApprovalTask;
