import { LogDTO } from './logDTO';
import Norm_TranslationDTO, { ISONorm_TranslationDTO } from './norm_TranslationDTO';
import TagDTO from './tagDTO';

export default class NormDTO {
  normId: number;

  authSchemaId?: number;
  
  active: boolean;

  isoNormId?: number;

  trans?: Norm_TranslationDTO[];

  isoNorm?: ISONormDTO;

  status?: number;

  constructor() {
    this.normId = 0;
    this.active = false;
  }
}

export class ISONormDTO {
  isoNormId: number;

  published: boolean;

  upgradeISONormId?: number;

  installUsingPackage: boolean;

  blockUpgrade?: boolean;

  allowDowngrade?: boolean;

  webURL?: string;

  norm?: NormDTO;

  trans?: ISONorm_TranslationDTO[];

  constructor() {
    this.isoNormId = 0;
    this.published = false;
    this.installUsingPackage = false;
  }
}

export class ISONormUpgradeResultDTO {
  sourceStandardId: number;

  targetStandardId: number;

  log: LogDTO;

  movedRelatedStandards: number[];

  upgradeLog: ISONormUpgradeLogDTO[];

  tag: TagDTO;

  taskCount: number;

  constructor() {
    this.sourceStandardId = 0;
    this.targetStandardId = 0;
    this.log = new LogDTO('');
    this.movedRelatedStandards = [];
    this.upgradeLog = [];
    this.tag = new TagDTO();
    this.taskCount = 0;
  }
}

export class ISONormUpgradeLogDTO {
  upgradeISONormId: number;

  sourceControlId: number;

  targetControlId: number;

  sourceISOControlId: number;

  targetISOControlId: number;

  spUpgraded?: boolean;

  constructor() {
    this.upgradeISONormId = 0;
    this.sourceControlId = 0;
    this.targetControlId = 0;
    this.sourceISOControlId = 0;
    this.targetISOControlId = 0;
  }
}
