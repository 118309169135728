import {
  Persona,
  PersonaSize,
  Stack,
  Text,
  Separator,
  TooltipHost,
  IconButton,
  IContextualMenuProps,
} from '@fluentui/react';
import { CommentTrailEntry } from 'models/comments';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { moreIcon, editIcon, deleteIcon, globalStackTokensGapSmall } from 'globalStyles';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import EditTextFieldCallOut from 'components/CallOuts/EditTextFieldCallOut';
import User from 'models/user';
import RichTextReadOnly from 'components/Text/RichTextReadOnly';

interface ISingleCommentProps {
  comment: CommentTrailEntry;
  isActionPending: boolean;
  onModify: (existingComment: CommentTrailEntry, updatedComment: string) => void;
  onRemove: (commentTrailId: number, commentTrailEntryId: number) => void;
  readOnly?: boolean;
}

const SingleComment = (props: ISingleCommentProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'commentTrail']);
  const [modify, setModify] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.comment.comment);
  const targetId = 'uniqueTargetId' + Math.random().toString(36).substring(7);

  const onModify = (newValue: string) => {
    props.onModify(props.comment, newValue);
    setValue(newValue);
  };

  const getUserName = (id: string, defaultName: string | undefined): string => {
    const user: User = appContext.globalDataCache.users.get(id);
    if (user.email === '' && defaultName) {
      return defaultName;
    }

    return user.name;
  };

  const editMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'modify',
        text: t('commentTrail:Modify'),
        iconProps: editIcon,
        onClick: () => {
          setValue(props.comment.comment);
          setModify(true);
        },
      },
      {
        key: 'remove',
        iconProps: deleteIcon,
        text: t('commentTrail:Remove'),
        onClick: () => setRemove(true),
      },
    ],
    directionalHintFixed: true,
  };

  //
  // Main render
  //
  return (
    <Stack>
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Persona
            text={getUserName(props.comment.createdById, props.comment.createdBy)}
            hidePersonaDetails={true}
            size={PersonaSize.size24}
          />
        </Stack.Item>
        <Stack.Item grow>
          <Stack>
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <Stack.Item>
                <Text block variant="small">
                  <strong>{getUserName(props.comment.createdById, props.comment.createdBy)}</strong>
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Text block nowrap variant="small">
                  {toLocaleDateTimeMedium(props.comment.created)}
                </Text>
              </Stack.Item>
              {props.comment.modifiedById && (
                <Stack.Item>
                  <TooltipHost
                    content={t('commentTrail:EditedTooltip', {
                      user: getUserName(props.comment.modifiedById, props.comment.modifiedBy),
                      date: toLocaleDateTimeMedium(props.comment.modified),
                    })}
                  >
                    <Text block variant="small">
                      <u>{t('commentTrail:Edited')}</u>
                    </Text>
                  </TooltipHost>
                </Stack.Item>
              )}
              {(!props.readOnly || appContext.user.id === props.comment.createdById) && (
                <Stack.Item id={targetId}>
                  <IconButton
                    styles={{ root: { height: 16 } }}
                    iconProps={moreIcon}
                    menuProps={editMenuProps}
                    disabled={props.isActionPending}
                  ></IconButton>
                </Stack.Item>
              )}
            </Stack>
            <Stack.Item>
              <RichTextReadOnly html={props.comment.comment} hideBorder={true} linkify={true} stripParagraph={true} />
              <EditTextFieldCallOut
                isBusy={props.isActionPending}
                value={value}
                isVisible={modify}
                onClose={() => setModify(false)}
                onUpdate={onModify}
                targetId={targetId}
                required={true}
                maxLenght={4000}
                width={700}
                isHtml={true}
              />
              <DialogYesNo
                hidden={!remove}
                onYes={() => {
                  setRemove(false);
                  props.onRemove(props.comment.commentTrailId, props.comment.commentTrailEntryId);
                }}
                onNo={() => setRemove(false)}
                title={t('commentTrail:Dialogs.Remove.Title')}
                subText={t('commentTrail:Dialogs.Remove.SubText')}
              ></DialogYesNo>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
      <Separator />
    </Stack>
  );
};

export default SingleComment;
