import parse from 'html-react-parser';
import { truncate } from './string';
import linkifyHtml from 'linkify-html';

export const stripHtmlParagraph = (html: string | undefined): string | undefined => {
  if (html?.startsWith('<p>') && html.endsWith('</p>')) {
    return html.substring(3, html.length - 4);
  } else {
    return html;
  }
};

export const getHtmlJSX = (
  html: string | undefined,
  stripContainingParagraph?: boolean,
): JSX.Element | JSX.Element[] | string | null => {
  if (stripContainingParagraph) {
    html = stripHtmlParagraph(html);
  }
  if (!html) return null;

  return parse(html);
};

export const exportHTMLString = (content: string): string => {
  const output = content.replace(/<img[^>]*>/g, '');

  return truncate(output, 32767);
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  let text = doc.documentElement.textContent || '';
  text = text.replaceAll('.', '. ');

  return text;
};

export const clearHtmlValue = (
  html: string | undefined,
  stripParagraph: boolean,
  replaceBreaks: boolean,
  linkify: boolean,
): string | undefined => {
  if (!html) return html;

  let newHtml = html;

  //replace new line with </br> for plain text
  if (replaceBreaks && !newHtml.startsWith('<')) {
    newHtml = newHtml.replaceAll('\r\n', '<br/>');
    newHtml = newHtml.replaceAll('\n', '<br/>');
  }

  //linkify
  if (linkify) {
    newHtml = linkifyHtml(newHtml ?? '', { target: '_blank' });
  }

  //strip containing paragraph to reduce padding
  if (stripParagraph) {
    newHtml = stripHtmlParagraph(newHtml) ?? '';
  }

  return newHtml;
};
