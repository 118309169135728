import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CommentTrailEntry } from 'models/comments';
import {
  apiRemoveComment,
  apiUpdateComment,
  apiAddComment,
  apiAddCommentForSpecificTenant,
} from 'services/Api/commentService';
import { apiRequest } from 'services/Auth/authConfig';
import AddComment from './AddComment';
import { Text, Spinner, SpinnerSize, Stack, SearchBox, List } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium } from 'globalStyles';
import { apiGetActivityTrail, apiGetActivityTrailForTenant } from 'services/Api/activityService';
import Activity from 'models/activity';
import ActivityTrailModel from 'models/ActivityTrail';
import { ActivityTrailItem } from './ActivityTrailItem';
import Entity, { EntityTypes } from 'models/entity';
import AuditTrailEntry from 'models/auditTrailEntry';
import { globalAdminTenantCommentName, globalFilterDelay, globalMinFilterChars } from 'globalConstants';
import User from 'models/user';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import { newGuidNil } from 'utils/guid';
import { hasUserDataPermission } from 'services/Auth/featurePermissions';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';

interface IActivityTrailProps {
  commentTrailId: number;
  auditTrailId: number;
  taskId?: number;
  controlId?: number;
  riskId?: number;
  themeId?: number;
  objectiveId?: number;
  processId?: number;
  packageId?: number;
  authSchemaIds: (number | undefined)[];
  onAddComment?: (comment: CommentTrailEntry) => void;
  tenantId?: string;
}

const ActivityTrail = (props: IActivityTrailProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'commentTrail', 'auditTrail']);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [activities, setActivities] = useState<(CommentTrailEntry | Activity | AuditTrailEntry)[]>([]);
  const [activitiesDisplay, setActivitiesDisplay] = useState<(CommentTrailEntry | Activity | AuditTrailEntry)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActionPending, setIsActionPending] = useState<boolean>(false);
  const [entity, setEntity] = useState<Entity | undefined>(undefined);
  const [canUpdate] = useState<boolean>(
    hasUserDataPermission(appContext, props.authSchemaIds, AuthSchemaLineOperation.Update),
  );

  useEffect(() => {
    //clean-up
    return () => {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //re-create list to render current pending status
    setActivitiesDisplay([...activitiesDisplay]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionPending]);

  useEffect(() => {
    if (
      props.commentTrailId ||
      props.taskId ||
      props.controlId ||
      props.riskId ||
      props.themeId ||
      props.objectiveId ||
      props.processId ||
      props.packageId ||
      props.auditTrailId
    ) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.commentTrailId,
    props.taskId,
    props.controlId,
    props.riskId,
    props.themeId,
    props.objectiveId,
    props.processId,
    props.packageId,
    props.auditTrailId,
  ]);

  const loadData = async () => {
    try {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      //load Data
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      let entityType = -1;
      let entityId = -1;

      if (props.taskId !== undefined) {
        entityType = EntityTypes.Task;
        entityId = props.taskId;
      }

      if (props.controlId !== undefined) {
        entityType = EntityTypes.Control;
        entityId = props.controlId;
      }

      if (props.riskId !== undefined) {
        entityType = EntityTypes.Risk;
        entityId = props.riskId;
      }

      if (props.themeId !== undefined) {
        entityType = EntityTypes.Requirement;
        entityId = props.themeId;
      }
      if (props.objectiveId !== undefined) {
        entityType = EntityTypes.Objective;
        entityId = props.objectiveId;
      }

      if (props.processId !== undefined) {
        entityType = EntityTypes.Process;
        entityId = props.processId;
      }

      if (props.packageId !== undefined) {
        entityType = EntityTypes.Package;
        entityId = props.packageId;
      }

      //save entity for updates
      const entity = new Entity(entityId, entityType);
      setEntity(entity);

      const commentTrailId = props.commentTrailId ? props.commentTrailId : 0;
      const auditTrailId = props.auditTrailId ? props.auditTrailId : 0;
      let activityTrail: ActivityTrailModel; //alias for ActivityTrail due to conflict with this class

      if (props.tenantId) {
        activityTrail = await apiGetActivityTrailForTenant(
          props.tenantId,
          auditTrailId,
          commentTrailId,
          entityType,
          entityId,
          accessToken,
        );
      } else {
        activityTrail = await apiGetActivityTrail(auditTrailId, commentTrailId, entityType, entityId, accessToken);
      }

      //sort on creation date descending
      const activites: (CommentTrailEntry | Activity | AuditTrailEntry)[] = [
        ...activityTrail.commentTrail.entries,
        ...activityTrail.auditTrailEntries,
        ...activityTrail.activities,
      ];

      activites.sort((a, b) => {
        const d1: Date = a.created;
        const d2: Date = b.created;

        if (d1 === d2) return 0;

        return d1 > d2 ? -1 : 1;
      });

      setActivities(activites);
      setActivitiesDisplay(activites);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddComment = async (newCommentValue: string, users: User[]) => {
    try {
      if (!props.commentTrailId) {
        return;
      }
      setIsActionPending(true);
      //appContext.showContentLoader();

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const comment = new CommentTrailEntry();
      comment.commentTrailId = props.commentTrailId;
      comment.created = new Date();
      comment.comment = newCommentValue;

      let newComment: CommentTrailEntry;
      if (props.tenantId) {
        comment.createdById = newGuidNil();
        comment.createdBy = globalAdminTenantCommentName;
        newComment = await apiAddCommentForSpecificTenant(props.tenantId, comment, accessToken);
      } else {
        newComment = await apiAddComment(comment, accessToken);
      }

      const newActivities = [newComment, ...activities];
      const newActivitiesDisplay = [newComment, ...activitiesDisplay];
      setActivities(newActivities);
      setActivitiesDisplay(newActivitiesDisplay);

      //callback with the new comment
      if (props.onAddComment) {
        props.onAddComment(newComment);
      }

      //send adaptive card to the mentioned users in Teams
      // if (props.taskId && users && users.length > 0) {
      //   const tasks = await apiGetSingleTask(props.taskId, false, accessToken, appContext.globalDataCache);
      //   const graphInterface = await appContext.getGraphInterface(graphTeamsCollaborationRequest.scopes);
      //   if (tasks && tasks.tasks.length > 0) {
      //     const content = adaptiveCardChatTaskComment(tasks.tasks[0], newCommentValue, t);

      //     const result = await graphSendChatOneToOne(graphInterface.client, users, appContext.user, content);
      //     if (!result) {
      //       appContext.showNotification('Could not send message to mentioned users', true);
      //     }
      //   }
      // }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsActionPending(false);
      //appContext.hideContentLoader();
    }
  };

  const onRemoveComment = async (commentTrailId: number, commentTrailEntryId: number) => {
    try {
      if (!commentTrailEntryId || !commentTrailId || !entity) {
        return;
      }
      setIsActionPending(true);
      //appContext.showContentLoader();

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiRemoveComment(commentTrailId, commentTrailEntryId, entity, accessToken);

      const newActivitiesDisplay: (CommentTrailEntry | Activity | AuditTrailEntry)[] = activitiesDisplay.filter(
        (activity: CommentTrailEntry | Activity | AuditTrailEntry) => {
          if (activity instanceof CommentTrailEntry) return activity.commentTrailEntryId !== commentTrailEntryId;

          return true;
        },
      );

      setActivities(activities);
      setActivitiesDisplay(newActivitiesDisplay);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsActionPending(false);
      //appContext.hideContentLoader();
    }
  };

  const onUpdateComment = async (existingComment: CommentTrailEntry, updatedComment: string) => {
    try {
      if (!entity) return;

      setIsActionPending(true);
      //appContext.showContentLoader();

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);

      existingComment.comment = updatedComment;
      existingComment.modified = new Date();
      existingComment.modifiedById = appContext.user.id;

      const newComment = await apiUpdateComment(existingComment, entity, accessToken);

      const existingCommentIndex = activities.findIndex((activity: CommentTrailEntry | Activity | AuditTrailEntry) => {
        if (activity instanceof CommentTrailEntry)
          return activity.commentTrailEntryId === existingComment.commentTrailEntryId;

        return false;
      });

      const newActivities = [...activities];
      newActivities[existingCommentIndex] = newComment;

      const existingCommentDisplayIndex = activitiesDisplay.findIndex(
        (activity: CommentTrailEntry | Activity | AuditTrailEntry) => {
          if (activity instanceof CommentTrailEntry)
            return activity.commentTrailEntryId === existingComment.commentTrailEntryId;

          return false;
        },
      );

      const newActivitiesDisplay = [...activitiesDisplay];
      newActivitiesDisplay[existingCommentDisplayIndex] = newComment;
      setActivities(newActivities);
      setActivitiesDisplay(newActivitiesDisplay);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsActionPending(false);
      //appContext.hideContentLoader();
    }
  };

  const getKey = (activity: CommentTrailEntry | Activity | AuditTrailEntry) => {
    if (activity instanceof CommentTrailEntry) {
      return activity.commentTrailEntryId;
    } else if (activity instanceof AuditTrailEntry) {
      return activity.auditTrailEntryId;
    } else {
      return activity.activityId;
    }
  };

  const onFilter = (ev: React.ChangeEvent<HTMLInputElement> | undefined, text: string | undefined): void => {
    if (!text || text.length < globalMinFilterChars) {
      setActivitiesDisplay(activities);
    } else {
      let newActivityEntries = activities.filter((a) => a instanceof Activity) as Activity[];
      let newCommentEntries = activities.filter((a) => a instanceof CommentTrailEntry) as CommentTrailEntry[];
      let newAuditTrailEntries = activities.filter((a) => a instanceof AuditTrailEntry) as AuditTrailEntry[];

      const textLower = text.toLowerCase();

      newActivityEntries = newActivityEntries.filter(
        (a) =>
          a.getPrimaryText(appContext.globalDataCache).toLowerCase().indexOf(textLower) >= 0 ||
          a.getSecondaryText(appContext.globalDataCache).toLowerCase().indexOf(textLower) >= 0 ||
          a.getUserName(appContext.globalDataCache)?.toLowerCase().indexOf(textLower) >= 0,
      );

      newCommentEntries = newCommentEntries.filter(
        (a) =>
          a.comment.toLowerCase().indexOf(textLower) >= 0 ||
          appContext.globalDataCache.users.get(a.createdById).name.toLowerCase().indexOf(textLower) >= 0 ||
          appContext.globalDataCache.users.get(a.modifiedById).name.toLowerCase().indexOf(textLower) >= 0,
      );

      newAuditTrailEntries = newAuditTrailEntries.filter(
        (a) =>
          a
            .getActivityText(t as unknown as TFunction<string[]>, appContext)
            .toLowerCase()
            .indexOf(textLower) >= 0 || a.userName.indexOf(textLower) >= 0,
      );

      //sort on creation date descending
      const newActivities: (CommentTrailEntry | Activity | AuditTrailEntry)[] = [
        ...newActivityEntries,
        ...newCommentEntries,
        ...newAuditTrailEntries,
      ];

      newActivities.sort((a, b) => {
        const d1: Date = a.created;
        const d2: Date = b.created;

        if (d1 === d2) return 0;

        return d1 > d2 ? -1 : 1;
      });

      setActivitiesDisplay(newActivities);
    }
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <SearchBox
          underlined
          placeholder={t('translation:General.Filter.Placeholder')}
          id="search"
          onChange={(ev, newValue) => {
            if (searchTimer) {
              clearTimeout(searchTimer);
            }
            setSearchTimer(setTimeout(() => onFilter(ev, newValue), globalFilterDelay));
          }}
          styles={{ root: { width: 300 } }}
        />
      </Stack.Item>
      <ScrollableStackItem isOnPanel={appContext.isMobileView} height={60} maxHeight={60}>
        {activities.length === 0 && (
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <Text variant="medium">{t('commentTrail:NoActivity')}</Text>
          </Stack>
        )}
        {activities.length > 0 && (
          <List
            items={activitiesDisplay}
            onRenderCell={(activity) => {
              if (!activity) return;

              return (
                <ActivityTrailItem
                  key={getKey(activity)}
                  onModifyComment={onUpdateComment}
                  onRemoveComment={onRemoveComment}
                  isActionPending={isActionPending}
                  ActivityItem={activity}
                  readOnly={!canUpdate || props.tenantId !== undefined}
                />
              );
            }}
          />
        )}
      </ScrollableStackItem>
      <Stack.Item>
        <AddComment onSend={onAddComment} isActionPending={isActionPending} />
      </Stack.Item>
    </Stack>
  );
};

export default ActivityTrail;
