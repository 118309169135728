import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DefaultButton,
  IconButton,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  Link,
  TooltipHost,
  IPivotItemProps,
} from '@fluentui/react';
import {
  cancelIcon,
  globalStackTokensGapSmall,
  globalStackItemStylesPaddingSmall,
  globalStackStylesHeight100,
  globalStackTokensGapExtraSmall,
  globalTextStylesBold,
} from 'globalStyles';
import Task, { TaskWorkflowStatus } from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import { NormalTaskDetail } from './NormalTaskDetail';
import AppContext from 'App/AppContext';
import CheckList from 'components/Checklist/Checklist';
import { apiGetSingleTask } from 'services/Api/taskService';
import { apiRequest } from 'services/Auth/authConfig';
import Joi from 'joi';
import ChecklistProgress from 'components/Checklist/ChecklistProgress';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import ActivityTrail from 'components/Activity/ActivityTrail';
import AuditTrail from 'components/Audit/AuditTrail';
import TaskLinkList from 'components/Links/LinkLists/TaskLinkList';
import { toDateOnly, toLocaleDateShort } from 'utils/datetime';
import { ToastContainer } from 'react-toastify';
import SingleTask, { ISingleTaskTypeProps } from '../SingleTask';
import { CheckListFollowUp } from 'components/Checklist/ChecklistFollowUp';
import { globalToastDelay } from 'globalConstants';
import TaskInstanceTab from '../TaskInstanceTab';
import OverlayLoader from 'components/Loading/OverlayLoader';
import { TaskContext } from '../TaskContext';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import TaskTypeForm from '../Forms/TaskTypeForm';
import TaskTopTasksBar from '../TaskTopTasksBar';
import { SystemTaskTypes } from 'models/tasks/taskType';
import { apiGetWebhooksForType } from 'services/Api/webhookService';
import InfoMessage from 'components/Notification/InfoMessage';
import WarningMessage from 'components/Notification/WarningMessage';
import TaskWorkflowDetailsModal from './TaskWorkflowDetailsModal';
import { TaskCheckListItem, TaskCheckList } from 'models/tasks/taskHelperClasses';
import { TaskDetailOwner } from './Details/TaskDetailOwner';
import { getCompactViewSwitch, getTaskViewModalStackStyles, getTaskViewModalStyles } from '../View/TaskRenderHelpers';
import { capitalizeFirstLetter } from 'utils/string';
import { canUpdateTaskField, TaskFieldTypes } from '../TaskAuthHelper';

interface INormalTaskProps extends ISingleTaskTypeProps {
  onSaveAsNewTask: () => void;
}

const NormalTask = (props: INormalTaskProps) => {
  const { t } = useTranslation(['translation', 'task']);
  const appContext = useContext(AppContext);
  const [err, setErr] = useState<Joi.ValidationError | undefined>(undefined);
  const [errStart, setErrStart] = useState<string | undefined>(undefined);
  const [isMasterTask, setIsMasterTask] = useState<Task | undefined>(undefined);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [webhooksLoadedForTemplateId, setWebhooksLoadedForTemplateId] = useState<number>(0);
  const [newEventHasWorkflows, setNewEventHasWorkflows] = useState<boolean>(false);
  const [showWorkflowDetails, setShowWorkflowDetails] = useState<boolean>(false);

  //
  // Effects
  //
  useEffect(() => {
    const _localizedFields = {
      assignment: t('task:Assignment.Label'),
      startDateTime: t('task:Start.Label'),
      endDateTime: t('task:End.ErrorMessage'),
      name: t('task:Name.Label'),
      deadline: t('task:Deadline.ErrorMessage'),
    };

    const loadWebhooks = async () => {
      try {
        if (!props.task.templateId) return;
        //check if the template that created this event has connected webhooks (which trigger a workflow)
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const webHooks = await apiGetWebhooksForType('task', props.task.templateId, accessToken);
        setNewEventHasWorkflows(webHooks.length > 0);
      } catch (err) {
        appContext.setError(err);
      }
    };

    const result = props.task.validate(_localizedFields);
    setErr(result.error);

    if (props.task.taskId === -1 && props.task.templateId && props.task.templateId !== webhooksLoadedForTemplateId) {
      setWebhooksLoadedForTemplateId(props.task.templateId);
      loadWebhooks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  //
  // Helpers
  //
  const validate = (fieldName: string): string | undefined => {
    if (err) {
      const fieldError = err.details.find((e) => {
        return e.context?.key === fieldName || e.message.toLowerCase().includes(fieldName.toLowerCase());
      });

      if (fieldError) {
        return fieldError.message;
      }
    }

    let newErrStart: string | undefined = undefined;
    if (fieldName === 'startDateTime') {
      //check the start date range for series and instances
      if (props.rescheduleDateRange) {
        if (props.task.startDateTime !== props.orgTask.startDateTime) {
          if (
            props.rescheduleDateRange.start &&
            !props.rescheduleDateRange.end &&
            toDateOnly(props.task.startDateTime) <= toDateOnly(props.rescheduleDateRange.start)
          ) {
            newErrStart = t('task:Start.Errors.After', { start: toLocaleDateShort(props.rescheduleDateRange.start) });
          } else if (
            props.rescheduleDateRange.end &&
            !props.rescheduleDateRange.start &&
            toDateOnly(props.task.endDateTime) >= toDateOnly(props.rescheduleDateRange.end)
          ) {
            newErrStart = t('task:Start.Errors.Before', { end: toLocaleDateShort(props.rescheduleDateRange.end) });
          } else if (
            props.rescheduleDateRange.end &&
            props.rescheduleDateRange.start &&
            (toDateOnly(props.task.startDateTime) <= toDateOnly(props.rescheduleDateRange.start) ||
              toDateOnly(props.task.endDateTime) >= toDateOnly(props.rescheduleDateRange.end))
          ) {
            newErrStart = t('task:Start.Errors.Between', {
              start: toLocaleDateShort(props.rescheduleDateRange.start),
              end: toLocaleDateShort(props.rescheduleDateRange.end),
            });
          }
        }
      }

      setErrStart(newErrStart);
    }

    if (newErrStart) {
      return newErrStart;
    }

    return undefined;
  };

  const hasErrors = (): boolean => {
    return err || errStart ? true : false;
  };

  const cannotSaveTask = (): boolean => {
    if (isMasterTask) {
      return hasErrors() || props.task.isEqual(props.orgTask);
    } else {
      return hasErrors() || (props.task.taskId !== -1 && props.task.isEqual(props.orgTask));
    }
  };

  const onCancel = () => {
    if (cannotSaveTask()) {
      props.onCancel();
    } else {
      setShowCancelDialog(true);
    }
  };

  const loadMasterTask = async () => {
    try {
      if (!props.task.taskMasterId) {
        return;
      }
      props.setIsActionPending(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const tasks = await apiGetSingleTask(props.task.taskMasterId, false, accessToken, appContext.globalDataCache);

      if (tasks.tasks && tasks.tasks.length > 0) {
        const masterTask = tasks.tasks[0];

        setErr(undefined);
        setErrStart(undefined);
        setIsMasterTask(masterTask);
      } else {
        appContext.showNotification(t('translation:General.Notifications.NotAvailable'), true);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      props.setIsActionPending(false);
    }
  };

  const addCommentFromCheckList = (item: TaskCheckListItem) => {
    props.setSelectedPivot('Comments');
  };

  const onOpenMaster = () => {
    loadMasterTask();
  };

  const onUpdateCheckList = (newCheckList: TaskCheckList) => {
    const copyTask = props.task.clone();
    copyTask.checkList = newCheckList;
    props.onUpdateTask(copyTask);
  };

  const onToggleTaskFollowUp = () => {
    const copyTask = props.task.clone();
    copyTask.followUp = copyTask.followUp ? false : true;
    props.onUpdateTask(copyTask);
  };

  const onClickForm = (taskTypeId: number) => {
    const newTask = props.task.clone();
    newTask.taskTypeId = taskTypeId > 0 ? taskTypeId : undefined;

    if (taskTypeId > 0) {
      newTask.systemTaskType =
        appContext.globalDataCache.taskTypes.get(taskTypeId).systemTaskType ?? SystemTaskTypes.None;
    } else {
      newTask.systemTaskType = SystemTaskTypes.None;
    }

    props.onUpdateTask(newTask);
    props.initPivot();
  };

  const getTaskDetails = () => {
    return (
      <NormalTaskDetail
        task={props.task}
        orgTask={props.orgTask}
        onUpdate={(newTask: Task) => {
          props.onUpdateTask(newTask);
        }}
        taskStates={props.task.taskStates!}
        getErrorMessage={validate}
        isActionPending={props.isActionPending}
        loadRescheduleRange={props.loadRescheduleRange}
        tags={props.tags}
        addTagToTaskState={props.addTagToTaskState}
        removeTagFromTaskState={props.removeTagFromTaskState}
        onChangeCompletionDate={props.onChangeCompletionDate}
        canUpdate={props.canUpdate}
        compactView={props.compactView}
      />
    );
  };

  const onClickFinish = () => {
    const newTask = props.task.clone();
    newTask.setComplete();
    props.onUpdateTask(newTask);
    props.onSave(newTask, false);
  };

  //
  // Render helpers
  //
  const getWorkflowTaskMessage = (newEventHasWorkflows: boolean): JSX.Element | null => {
    if (props.task.recurringPattern.isActive) {
      //normal task series are recurring and a workflow cannot be started for recurring tasks
      return null;
    }
    if (props.task.taskId === -1 && newEventHasWorkflows) {
      return <InfoMessage message={t('task:Workflows.Messages.TaskStart')} />;
    } else {
      if (props.task.webhookStatus === TaskWorkflowStatus.Success) {
        return (
          <InfoMessage>
            <Stack horizontal tokens={globalStackTokensGapExtraSmall}>
              <Text variant="small"> {t('task:Workflows.Messages.TaskSuccess')} </Text>
              <Link onClick={() => setShowWorkflowDetails(true)}>{t('task:Workflows.Messages.DetailLink')}</Link>
            </Stack>
          </InfoMessage>
        );
      } else if (props.task.webhookStatus === TaskWorkflowStatus.Failure) {
        return (
          <WarningMessage>
            <Stack horizontal tokens={globalStackTokensGapExtraSmall}>
              <Text styles={{ root: { color: 'black' } }} variant="small">
                {t('task:Workflows.Messages.TaskSuccess')}
              </Text>
              <Link onClick={() => setShowWorkflowDetails(true)}>{t('task:Workflows.Messages.DetailLink')}</Link>
            </Stack>
          </WarningMessage>
        );
      } else {
        return null;
      }
    }
  };

  const getOutlookButton = (): JSX.Element | null => {
    if (!props.task.eventId && (props.task.isNonRecurring() || props.task.isSeries())) {
      //create
      return (
        <Stack>
          <Stack.Item>
            <PrimaryButton
              className="redlab-usetiful-task-scheduleinoutlook"
              disabled={
                !props.task.isAssigned() ||
                hasErrors() ||
                props.isActionPending ||
                !canUpdateTaskField(props.task, props.canUpdate, TaskFieldTypes.OutlookCreate, appContext)
              }
              onClick={() => {
                props.createInOutlook();
              }}
            >
              {t('task:Outlook.CreateOutlook')}
            </PrimaryButton>
          </Stack.Item>
        </Stack>
      );
    } else if (props.task.eventId && (props.task.isNonRecurring() || props.task.isSeries())) {
      //remove
      return (
        <Stack>
          <Stack.Item>
            <PrimaryButton
              text={t('task:Outlook.RemoveOutlook')}
              disabled={
                hasErrors() ||
                props.isActionPending ||
                !canUpdateTaskField(props.task, props.canUpdate, TaskFieldTypes.OutlookRemove, appContext)
              }
              onClick={() => {
                props.removeFromOutlook();
              }}
            />
          </Stack.Item>
        </Stack>
      );
    }

    return null;
  };

  const onRenderTaskTypePivotItem = (
    itemProps?: IPivotItemProps,
    defaultRender?: (itemProps?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null => {
    //itemScope contains true when in compact view
    if (!itemProps || !itemProps.itemKey || !defaultRender) return null;

    //render default for compact mode
    if (props.compactView === true) {
      return defaultRender(itemProps);
    }

    return (
      <Stack horizontal tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text variant="medium" styles={itemProps.itemKey === props.selectedPivot ? globalTextStylesBold : undefined}>
            {itemProps.headerText}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <TooltipHost content={t('forms:View.EnterFormMode')}>
            <IconButton iconProps={{ iconName: 'Fullscreen' }} onClick={() => props.setFormMode(true)} />
          </TooltipHost>
        </Stack.Item>
      </Stack>
    );
  };

  const footer = (
    <Stack horizontal={!appContext.isMobileView} verticalAlign="end" tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            disabled={cannotSaveTask() || props.isActionPending}
            onClick={() => {
              props.onSave(props.task, false);
            }}
          >
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              onCancel();
            }}
          >
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack>
      </Stack.Item>
      <Stack.Item grow>
        <Stack horizontal horizontalAlign={appContext.isMobileView ? undefined : 'space-around'}>
          {getOutlookButton()}
        </Stack>
      </Stack.Item>
    </Stack>
  );

  //
  // Main render
  //
  return (
    <Modal forceFocusInsideTrap={false} isOpen={props.isOpen} styles={getTaskViewModalStyles(props.windowLevel)}>
      <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="normalTask" />
      {props.isLoading && <OverlayLoader text={t('task:Progress.Save')} />}
      <Stack
        verticalFill
        tokens={globalStackTokensGapSmall}
        styles={getTaskViewModalStackStyles(appContext.isMobileView, props.compactView)}
      >
        <Stack.Item>
          <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Stack.Item styles={{ root: { width: props.compactView ? '90%' : undefined } }}>
              {props.task.taskId === -1 && (
                <Text block nowrap variant="xxLarge">
                  {t('task:Title.Normal.New')}
                </Text>
              )}
              {props.task.taskId !== -1 && props.compactView && (
                <TooltipHost content={props.task.name}>
                  <Text block nowrap variant="xxLarge">
                    {capitalizeFirstLetter(props.task.name)}
                  </Text>
                </TooltipHost>
              )}
              {props.task.taskId !== -1 && !props.compactView && (
                <Text variant="xxLarge">
                  {!props.task.isSeries() && t('task:Title.Normal.Edit')}
                  {props.task.isSeries() && t('task:Title.Normal.Series.Edit')}
                </Text>
              )}
            </Stack.Item>
            {!props.compactView && (
              <Stack.Item>
                <TaskTopTasksBar
                  task={props.task}
                  onClickTemplate={props.copyFromTemplate}
                  onClickOpenMaster={props.task.isInstance() ? onOpenMaster : undefined}
                  onClickFinish={onClickFinish}
                  onClickFollowUp={!props.task.isSeries() ? props.createFromTemplate : undefined}
                  onClickRemove={() => setShowRemoveDialog(true)}
                  onClickForm={props.task.isSeries() ? onClickForm : undefined}
                  onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
                  onSaveAsNewTask={props.onSaveAsNewTask}
                  navigateExternal={props.navigateExternal}
                  canUpdate={props.canUpdate}
                  canDelete={props.canDelete}
                  orgTask={props.orgTask}
                  canSave={!hasErrors()}
                />
              </Stack.Item>
            )}
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <TaskDetailOwner
              orgTask={props.orgTask}
              task={props.task}
              onUpdate={props.onUpdateTask}
              canUpdate={props.canUpdate}
            />
          </Stack.Item>
          <Separator />
          {getWorkflowTaskMessage(newEventHasWorkflows)}
        </Stack.Item>
        {props.compactView && (
          <Stack.Item>
            <TaskTopTasksBar
              task={props.task}
              onClickTemplate={props.copyFromTemplate}
              onClickOpenMaster={props.task.isInstance() ? onOpenMaster : undefined}
              onClickFinish={onClickFinish}
              onClickFollowUp={!props.task.isSeries() ? props.createFromTemplate : undefined}
              onClickRemove={() => setShowRemoveDialog(true)}
              onClickForm={props.task.isSeries() ? onClickForm : undefined}
              onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
              onSaveAsNewTask={props.onSaveAsNewTask}
              navigateExternal={props.navigateExternal}
              compact={true}
              canUpdate={props.canUpdate}
              canDelete={props.canDelete}
              orgTask={props.orgTask}
              canSave={!hasErrors()}
            />
          </Stack.Item>
        )}
        <Stack.Item grow>
          <Stack horizontal styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
            {!props.compactView && (
              <Fragment>
                <Stack.Item styles={{ root: { width: '50%' } }}>{getTaskDetails()}</Stack.Item>
                <Separator vertical></Separator>
              </Fragment>
            )}
            <Stack.Item styles={!props.compactView ? { root: { width: '50%' } } : { root: { width: '100%' } }}>
              <Stack verticalFill tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <Stack horizontal verticalAlign="start">
                    {getCompactViewSwitch(
                      props.task,
                      appContext.isMobileView,
                      props.compactView,
                      t,
                      props.setCompactView,
                      appContext,
                    )}
                    <Stack.Item>
                      <Pivot
                        selectedKey={props.selectedPivot}
                        onLinkClick={(item) => props.setSelectedPivot(item?.props.itemKey ?? '')}
                        overflowBehavior="menu"
                      >
                        {props.compactView && (
                          <PivotItem key="TaskDetails" itemKey="TaskDetails" headerText={t('task:Pivot.TaskDetails')} />
                        )}
                        {props.hasFormFeature && props.task.taskTypeId && props.task.taskTypeId > 0 && (
                          <PivotItem
                            key="TaskType"
                            itemKey="TaskType"
                            headerText={appContext.globalDataCache.taskTypes.get(props.task.taskTypeId).tabName}
                            onRenderItemLink={appContext.isMobileView ? undefined : onRenderTaskTypePivotItem}
                          />
                        )}
                        {(!props.task.taskTypeId || props.task.taskTypeId === 0 || !props.hasFormFeature) && (
                          <PivotItem key="Checklist" itemKey="Checklist" headerText={t('task:Pivot.Checklist')} />
                        )}
                        <PivotItem key="Context" itemKey="Context" headerText={t('task:Pivot.Context')} />
                        <PivotItem key="Resources" itemKey="Resources" headerText={t('task:Pivot.Resources')} />
                        {props.task.commentTrailId > 0 && (
                          <PivotItem key="Activities" itemKey="Activities" headerText={t('task:Pivot.Activities')} />
                        )}
                        {(props.task.isSeries() ||
                          isMasterTask ||
                          (props.task.isInstance() && props.task.taskMasterId)) && (
                          <PivotItem key="Instances" itemKey="Instances" headerText={t('task:Pivot.Instances')} />
                        )}
                        {props.task.auditTrailId > 0 && (
                          <PivotItem key="Audit" itemKey="Audit" headerText={t('task:Pivot.Audit')} />
                        )}
                      </Pivot>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                {props.selectedPivot === 'TaskDetails' && <Stack.Item grow>{getTaskDetails()}</Stack.Item>}
                <TaskTypeForm
                  isOpen={props.selectedPivot === 'TaskType'}
                  task={props.task}
                  links={props.links}
                  compact={true}
                  compactTaskView={props.compactView}
                  readonly={props.task.isCompleted()}
                  onUpdateData={props.onUpdateKPIData}
                  onSetData={props.onSetKPIData}
                  onUpdateTaskForForm={props.onUpdateTaskForForm}
                  addLinks={props.addLinkToTaskState}
                  setLinks={props.setLinkToTaskState}
                  removeLink={props.removeLinkFromTaskState}
                  showErrors={props.showFormErrors}
                  onValidate={props.onValidateForm}
                  formMode={props.formMode}
                  onExitFormMode={() => props.setFormMode(false)}
                ></TaskTypeForm>
                {props.selectedPivot === 'Checklist' && (
                  <Fragment>
                    <Stack.Item styles={globalStackItemStylesPaddingSmall}>
                      <ChecklistProgress
                        totalChecks={props.task.checkList.items ? props.task.checkList.items.length : 0}
                        completedChecks={props.task.checkList.getCompletedCheckCount()}
                        followUp={props.task.followUp ? true : false}
                      />
                    </Stack.Item>
                    <Stack.Item grow>
                      <CheckList
                        allowFill={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistFill,
                          appContext,
                        )}
                        allowEdit={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistEdit,
                          appContext,
                        )}
                        checkList={props.task.checkList}
                        updateCheckList={onUpdateCheckList}
                        onComment={addCommentFromCheckList}
                      />
                    </Stack.Item>
                    {props.task.taskId !== -1 && props.canUpdate && (
                      <Stack.Item style={{ paddingLeft: '5px' }}>
                        <CheckListFollowUp followUp={props.task.followUp} toggle={onToggleTaskFollowUp} />
                      </Stack.Item>
                    )}
                  </Fragment>
                )}
                {props.selectedPivot === 'Resources' && (
                  <TaskLinkList
                    taskLinks={props.links}
                    task={props.task}
                    addLinks={props.addLinkToTaskState}
                    setLinks={props.setLinkToTaskState}
                    removeLink={props.removeLinkFromTaskState}
                    navigateExternal={props.navigateExternal}
                  />
                )}
                {props.selectedPivot === 'Activities' && (
                  <Stack.Item grow style={{ height: appContext.isMobileView ? '50vh' : 0 }}>
                    <ActivityTrail
                      auditTrailId={props.task.auditTrailId}
                      commentTrailId={props.task.commentTrailId}
                      taskId={props.task.taskId}
                      authSchemaIds={[props.task.authSchemaId]}
                    />
                  </Stack.Item>
                )}
                {props.selectedPivot === 'Instances' && (
                  <Stack.Item grow>
                    <TaskInstanceTab
                      taskId={
                        props.task.isInstance()
                          ? props.task.taskMasterId
                            ? props.task.taskMasterId
                            : props.task.taskId
                          : props.task.taskId
                      }
                      activeTaskId={props.task.isInstance() ? props.task.taskId : undefined}
                      windowLevel={props.windowLevel}
                      navigateExternal={props.navigateExternal}
                    />
                  </Stack.Item>
                )}
                {props.selectedPivot === 'Audit' && (
                  <Stack.Item grow style={{ height: appContext.isMobileView ? '30vh' : 0 }}>
                    <AuditTrail auditTrailId={props.task.auditTrailId} />
                  </Stack.Item>
                )}
                {props.selectedPivot === 'Context' && (
                  <Stack.Item grow>
                    <TaskContext
                      task={props.task}
                      updateTaskForForm={props.onUpdateTaskForForm}
                      updateTask={props.onUpdateTask}
                      allowEdit={canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Context, appContext)}
                      windowLevel={props.windowLevel}
                      navigateExternal={props.navigateExternal}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Separator styles={{ root: { height: 1 } }} />
        </Stack.Item>
        <Stack.Item>{footer}</Stack.Item>
      </Stack>
      <DialogConfirmDelete
        onNo={() => {
          setShowRemoveDialog(false);
        }}
        onYes={() => {
          setShowRemoveDialog(false);
          props.onRemove();
        }}
        title={props.task.isSeries() ? t('task:RemoveSeries.DialogTitle') : t('task:Remove.DialogTitle')}
        subText={props.task.isSeries() ? t('task:RemoveSeries.DialogSubTitle') : t('task:Remove.DialogSubTitle')}
        hidden={!showRemoveDialog}
        confirmText={!props.task.isSeries() ? t('task:Remove.RemoveDialogConfirmText') : undefined}
      />
      <DialogYesNo
        onNo={() => {
          setShowCancelDialog(false);
        }}
        onYes={() => {
          setShowCancelDialog(false);
          props.onCancel();
        }}
        title={t('task:Cancel.DialogTitle')}
        subText={t('task:Cancel.DialogSubTitle')}
        hidden={!showCancelDialog}
      />
      {isMasterTask && (
        <SingleTask
          isOpen={isMasterTask !== undefined}
          task={isMasterTask}
          windowLevel={(props.windowLevel ?? 0) + 1}
          close={() => setIsMasterTask(undefined)}
          onSave={() => setIsMasterTask(undefined)}
          onUpdate={() => {}}
          onRemove={() => props.onRemove()}
        />
      )}
      <TaskWorkflowDetailsModal
        hidden={!showWorkflowDetails}
        task={props.task}
        onClose={() => setShowWorkflowDetails(false)}
      />
    </Modal>
  );
};

export default NormalTask;
