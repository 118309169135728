import {
  Callout,
  Stack,
  TextField,
  PrimaryButton,
  DefaultButton,
  Text,
  DirectionalHint,
  Separator,
} from '@fluentui/react';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { isEmpty } from 'utils/string';
import RichTextEditor, { IRichTextEditor } from 'components/Text/RichTextEditor';
import { clearHtmlValue } from 'utils/html';

interface IEditTextFieldCallOut {
  targetId: string;
  onClose: () => void;
  onUpdate: (newText: string) => void;
  isVisible: boolean;
  value?: string;
  isBusy?: boolean;
  maxLenght?: number;
  minLength?: number;
  height?: number;
  width?: number;
  maxHeight?: number;
  required?: boolean;
  readOnly?: boolean;
  isHtml?: boolean;
}

const EditTextFieldCallOut = (props: IEditTextFieldCallOut) => {
  const { t } = useTranslation(['translation']);
  const appContext = useContext(AppContext);
  const editorRef = useRef<IRichTextEditor | null>(null);
  const [newValue, setNewValue] = useState<string>('');

  useEffect(() => {
    const setValue = (value: string | undefined) => { 
      if (props.isHtml) {
        const newHtml = clearHtmlValue(value, false, true, true);
        if (newHtml && newHtml !== value) {
          props.onUpdate(newHtml);
        }
        setNewValue(newHtml || '');
      } else {
        setNewValue(value || '');
      }
    }
    
    if (props.isVisible) {
      setValue(props.value);
      editorRef.current?.focus();
    } else {
      setNewValue('');
    }
  }, [props, props.isVisible, props.value]);

  const getEditor = (): JSX.Element => {
    if (props.isHtml) {
      return (
        <RichTextEditor
          ref={editorRef}
          html={newValue}
          directEdit={true}
          maxLength={props.maxLenght}
          disallowFullscreen={true}
          onChange={(value) => {
            if (value === undefined) setNewValue('');
            else setNewValue(value);
          }}
          compactToolbar={true}
        />
      );
    } else {
      return (
        <Fragment>
          <TextField
            required={props.required}
            readOnly={props.readOnly}
            autoFocus={!appContext.isMobileView}
            value={newValue}
            style={{
              resize: 'vertical',
            }}
            rows={5}
            multiline
            maxLength={props.maxLenght}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
              if (newValue === undefined) setNewValue('');
              else setNewValue(newValue);
            }}
          />
          {(props.minLength || props.maxLenght) && (
            <Text variant="small">{t('translation:General.Text.Length', { len: newValue.length })}</Text>
          )}
        </Fragment>
      );
    }
  };

  //
  // Main render
  //
  return (
    <Callout
      hidden={!props.isVisible}
      styles={{
        root: {
          padding: '10px 12px',
          maxHeight: props.maxHeight || 400,
          height: props.height || undefined,
          width: props.width || 500,
          maxWidth: '90vw',
        },
      }}
      target={`#${props.targetId}`}
      directionalHint={DirectionalHint.bottomLeftEdge}
      onDismiss={(ev) => {
        props.onClose();
      }}
      preventDismissOnEvent={(ev) => {
        return true;
      }}
    >
      <Stack tokens={globalStackTokensGapSmall}>
        <Stack.Item>{getEditor()}</Stack.Item>
        <Separator />
        <Stack.Item>
          <Stack horizontal tokens={globalStackTokensGapSmall} horizontalAlign="end">
            <Stack.Item>
              <PrimaryButton
                onClick={() => {
                  props.onUpdate(newValue);
                  props.onClose();
                }}
                disabled={
                  props.isBusy ||
                  props.readOnly ||
                  (props.required === true && isEmpty(newValue)) ||
                  (props.minLength !== undefined && props.minLength > 0 && newValue.length < props.minLength)
                }
              >
                {t('translation:General.Button.Save')}
              </PrimaryButton>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                onClick={() => {
                  props.onClose();
                }}
              >
                {t('translation:General.Button.Cancel')}
              </DefaultButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Callout>
  );
};

export default EditTextFieldCallOut;
