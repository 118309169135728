import { SubscriptionTypes } from 'utils/subscription';
import { StoreMgmtStates } from './adminTenant';
import { Settings } from './setting';

export const LeadSources_Instant27001 = '7a2561e1-c2f4-496f-8983-360704dd1bce';
export const LeadSources_CAN = '56e4347c-073c-4882-b5b2-9fd9a838c763';
export const LeadSourceInfosentry = '3da37c7b-d723-47b0-a597-5d96c162eec9';
export const LeadSources: string[] = [LeadSources_Instant27001, LeadSources_CAN, LeadSourceInfosentry];

export default class Tenant {
  id: string;

  parentId?: string;

  topLevelParentTenantId: string;

  azureTenantId?: string;

  name: string;

  created?: Date;

  totalLicenses: number;

  usedUserLicenses: number;

  usedMgMtLicenses: number;

  numberOfUsers: number;

  userLicenses: number;

  mgMtLicenses: number;

  storeMgmtState: number;

  onboardingState?: TenantOnboarding;

  subscriptionType: number;

  settings?: Settings;

  timeZoneCode?: string;

  currentUserLicensed?: boolean;

  appLogo?: Blob;

  constructor(id: string, name: string) {
    this.id = id;
    this.topLevelParentTenantId = id; //default same as id
    this.name = name;
    this.totalLicenses = 0;
    this.usedUserLicenses = 0;
    this.usedMgMtLicenses = 0;
    this.numberOfUsers = 0;
    this.userLicenses = 0;
    this.mgMtLicenses = 0;
    this.storeMgmtState = StoreMgmtStates.Customer;
    this.subscriptionType = SubscriptionTypes.Basic;
  }

  getUsedLicenses(): number {
    return this.usedUserLicenses + this.usedMgMtLicenses;
  }

  getFreeLicenses(): number {
    return this.totalLicenses - this.getUsedLicenses();
  }

  getFreeUserLicenses(): number {
    return this.userLicenses - this.usedUserLicenses;
  }

  getFreeMgMtLicenses(): number {
    return this.mgMtLicenses - this.usedMgMtLicenses;
  }

  static isDescendentOf(child: Tenant, parentId: string | undefined, orgUnits: Tenant[]): boolean {
    if (parentId && child.parentId) {
      if (child.parentId === parentId) return true;
      let nextParent: Tenant | undefined = child;

      nextParent = orgUnits.find((o) => o.id === nextParent?.parentId);
      if (!nextParent) return false;

      return this.isDescendentOf(nextParent, parentId, orgUnits);
    }

    return false;
  }

  clone() {
    const newTenant = new Tenant(this.id, this.name);
    newTenant.parentId = this.parentId;
    newTenant.topLevelParentTenantId = this.topLevelParentTenantId;
    newTenant.azureTenantId = this.azureTenantId;
    newTenant.totalLicenses = this.totalLicenses;
    newTenant.usedUserLicenses = this.usedUserLicenses;
    newTenant.usedMgMtLicenses = this.usedMgMtLicenses;
    newTenant.usedUserLicenses = this.usedUserLicenses;
    newTenant.numberOfUsers = this.numberOfUsers;
    newTenant.userLicenses = this.userLicenses;
    newTenant.mgMtLicenses = this.mgMtLicenses;
    newTenant.storeMgmtState = this.storeMgmtState;
    newTenant.subscriptionType = this.subscriptionType;

    return newTenant;
  }
}

export class TenantOnboarding {
  packageIds: string[];

  leadSource?: string;

  onboardingSteps: TenantOnboardingSteps;

  constructor() {
    this.packageIds = [];
    this.onboardingSteps = new TenantOnboardingSteps();
  }

  clone() {
    const newTenantOnboarding = new TenantOnboarding();
    newTenantOnboarding.packageIds = [...this.packageIds];
    newTenantOnboarding.leadSource = this.leadSource;
    newTenantOnboarding.onboardingSteps = this.onboardingSteps.clone();

    return newTenantOnboarding;
  }
}

export class TenantOnboardingSteps {
  step1: boolean;

  step2: boolean;

  step3: boolean;

  constructor() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
  }

  clone() {
    const newTenantOnboardingSteps = new TenantOnboardingSteps();
    newTenantOnboardingSteps.step1 = this.step1;
    newTenantOnboardingSteps.step2 = this.step2;
    newTenantOnboardingSteps.step3 = this.step3;

    return newTenantOnboardingSteps;
  }
}
